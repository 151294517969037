/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
      <mesh geometry={nodes.Cube.geometry} material={materials.Material} />
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/darm.gltf')

  useFrame((state, delta) => {
    group.current.rotation.y += 0.01
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh scale={0.08} geometry={nodes.foo.geometry} material={materials.material_0} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('/darm.gltf')
